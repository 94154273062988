<template>
    <v-container fluid class="cms cms-editor grey lighten-5 my-0 px-0  py-0 cms">
        <div class="row pa-5">
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" class="agent_profiles">
                        <h2>Edit {{Division}} Profiles</h2>
                        <v-progress-linear v-if="loading" indeterminate color="primary"></v-progress-linear>
                        <v-expansion-panels>
                            <v-expansion-panel v-for="(section, key) in sections" :key="key">
                                <v-expansion-panel-header :id="section">
                                    <div>{{section}}
                                        <v-btn fab small icon @click.stop="newProfile(section)">
                                            <v-icon small>fas fa-plus-circle</v-icon>
                                        </v-btn>
                                    </div>
                                </v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <v-row wrap>
                                        <v-col cols="12" sm="3" v-for="(profile, ix) in section_profiles(section)" :key="ix">
                                            <agent-profile-card v-on:showupload="showUpload" :agent="profile" :sections="sections"></agent-profile-card>
                                        </v-col>
                                    </v-row>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-col>
                </v-row>
            </v-col>
        </div>
        <v-dialog v-model="new_dialog" width="500">
            <div class="agent_profiles">
                <agent-profile-card ref="new_agent_dialog" v-on:closeDialog="new_dialog = false" v-on:showupload="showUpload" :sections="sections"></agent-profile-card>
            </div>
        </v-dialog>
        <v-dialog v-model="upload_dialog" width="500">
            <v-card class="pa-6 text-center" min-height="">
                <div>
                    <p>Profile photos should be square and no smaller than 200px x 200px and 1:1 Image Ratio (Square)</p>
                    <vue-dropzone ref="myVueDropzone" v-on:vdropzone-success="uploadComplete" id="dropzone" :options="dropzoneOptions"></vue-dropzone>
                </div>
            </v-card>
        </v-dialog>
    </v-container>
</template>
<script>
import { getInstance } from "@/auth/index";
import QuilityAPI from '@/store/API/QuilityAPI'
import AgentProfile from '@/store/Models/AgentProfile'
import AgentProfileCard from './components/AgentProfileCard.vue'
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
export default {
    data() {
        return {
            upload_dialog: false,
            new_dialog: false,
            loading: false,
            sections: [],
            Division: 'Leadership',
            dropzoneOptions: {
                url: '/api/private/agentprofiles/photo',
                headers: {},
                addRemoveLinks: true,
                acceptedFiles: 'image/*',
                'paramName': 'image',
                thumbnailWidth: 100,
                thumbnailHeight: 100,
            },
            uploadCallback: function() {},
        }
    },
    mounted: function() {
        this.loading = true;
        var g = this;
        QuilityAPI.getAgentProfiles(this.Division).then(function(resp) {
            g.sections = resp.meta.Sections
            g.loading = false;
        }).catch(function(err) {
            g.showError(err)
            g.loading = false;
        })
        var g = this
        this.config().then(function(token) {
            g.token = token
            g.dropzoneOptions.headers = {
                'Authorization': `Bearer ${token}`,
                'Accept': 'application/json',
                //'X-Requested-With': 'XMLHttpRequest',
            }
        })
    },
    computed: {

    },
    methods: {
        'section_profiles': function(section) {
            return AgentProfile.query().where('Division', this.Division).where('Section', section).orderBy('DisplayName', 'asc').get();
        },
        showUpload: function(callback) {
            this.uploadCallback = callback
            this.upload_dialog = true
        },
        uploadComplete: function(file) {
            var resp = JSON.parse(file.xhr.response);
            this.uploadCallback(resp.file, resp.public_path)
            this.upload_dialog = false
        },
        config: function() {
            return new Promise((resolve, reject) => {
                getInstance().getTokenSilently().then(function(token) {
                    resolve(token);
                });
            })
        },
        newProfile: function(section) {
            this.new_dialog = true;
            var g = this
            this.$nextTick(function() {
                g.$refs.new_agent_dialog.agent.Section = section
                g.$refs.new_agent_dialog.agent.Division = this.Division
            });
        }
    },
    components: {
        AgentProfileCard,

        vueDropzone: vue2Dropzone,
    }
}

</script>
