<template>
    <v-card class="text-center pa-2">
        <img v-if="agent.ProfilePhoto" @click="showUpload" @error="imageLoadError" class="agent_profile_photo" :src="agent.ProfilePhoto"></img><br>
        <v-btn @click="showUpload">Upload Photo</v-btn>
        <v-card-title class="text-left">
            {{agent.DisplayName}}
        </v-card-title>
        <v-card-text class="text-left">
            <v-text-field label="Display Name" v-model="agent.DisplayName"></v-text-field>
            <v-text-field v-if="agent.Division == 'Leadership'" label="Agent Code" v-model="agent.AgentCode"></v-text-field>
            <v-text-field v-if="agent.Division == 'Corporate'" label="Title" v-model="agent.Title"></v-text-field>
            <v-text-field v-if="agent.Division == 'Corporate'" label="Email" v-model="agent.Email"></v-text-field>
            <v-select label="Section" :items="sections" v-model="agent.Section"></v-select>
            <v-select label="Titles" v-if="agent.Division == 'Leadership'" chips multiple :items="titles" v-model="agent.Titles"></v-select>
            <v-textarea v-if="agent.Division == 'Corporate'" label="Bio" v-model="agent.Bio"></v-textarea>
        </v-card-text>
        <v-card-actions>
            <v-spacer></v-spacer>
            <v-progress-circular size="25" indeterminate v-if="loading"></v-progress-circular>
            <v-btn color="primary" @click="saveAgentProfile">Save</v-btn>
            <v-btn @click="deleteAgentProfile">Delete</v-btn>
        </v-card-actions>
    </v-card>
</template>
<script>
import QuilityAPI from '@/store/API/QuilityAPI'
import AgentProfile from '@/store/Models/AgentProfile'
export default {
    props: {
        'agent': {
            type: Object,
            default: function() {
                return {
                    DisplayName: '',
                    Titles: [],
                    Section: 'Agency Owner',
                    AgentCode: '',
                    ProfilePhoto: '',
                    Division: '',
                    file: ''
                }
            }
        },
        'sections': {
            type: Array,
            default: function() {
                []
            }
        }
    },
    data: function() {
        return {
            loading: false,
            titles: [{
                    text: "Advisory Board",
                    value: "AB"
                },
                {
                    text: "Equity Partner",
                    value: "EP"
                },
                {
                    text: "Top Producer",
                    value: "TP"
                },
                {
                    text: "Elite Producer",
                    value: "ELP"
                }
            ]
        }
    },
    mounted: function() {
        if (typeof this.agent.ProfilePhoto == 'undefined') {
            this.agent.ProfilePhoto = "XXX";
        }
    },
    methods: {
        deleteAgentProfile: function() {
            QuilityAPI.deleteAgentProfile(this.agent).then(function() {
                //console.log('Deleted')
            })
        },
        saveAgentProfile: function() {
            var g = this;
            this.loading = true
            if (typeof this.agent.id == 'undefined' || this.agent.id == null) {
                QuilityAPI.createAgentProfile(this.agent).then(function() {
                    //AgentProfile.insert({ data: g.agent });
                    g.$emit('closeDialog', g.agent)
                    g.agent = {
                        DisplayName: '',
                        Titles: [],
                        Section: 'Agency Owner',
                        AgentCode: '',
                        ProfilePhoto: '',
                        Bio: '',
                        Email: '',
                        Title: '',
                        Division: '',
                        file: ''
                    }
                    g.loading = false
                }).catch(function(err) {
                    g.showError("Error: " + error);
                    g.$emit('closeDialog')
                    g.loading = false
                })
            } else {
                QuilityAPI.saveAgentProfile(this.agent).then(function() {
                    //AgentProfile.insert({ data: g.agent });
                    g.loading = false
                }).catch(function(err) {
                    g.showError("Error: " + error);
                    g.loading = false
                })
            }
        },
        showUpload: function() {
            var g = this
            //passes a callback function to the upload on what to do with the info returned after saving the profile photo. 
            this.$emit('showupload', function(file, public_path) {
                g.$set(g.agent, "ProfilePhoto", public_path);
                g.$set(g.agent, "file", file);
                if (typeof g.agent.id != 'undefined' || g.agent.id != null) {
                    AgentProfile.insert({ data: g.agent });
                }
            })
        },
        imageLoadError: function(e) {
            //console.log(e)
            //this.agent.ProfilePhoto = "XXX"
        }
    },
    watch: {
        'agent.ProfilePhoto': function(newV) {
            console.log(newV)
        }
    },
    components: {

    }
}

</script>
<style scoped>
.agent_profile_photo {}

</style>
